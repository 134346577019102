//$backgroundImage: url('../images/home/Bg-Dektop-Lista@2x.png'  )
@media only screen and (min-width: 992px)
    .body_inicio
        margin-bottom: 50px
        margin-top: 4%
    .container
        max-width: 100%
        margin-right: auto
        margin-left: auto
   
        padding-left: 15%
        padding-right: 15%
        margin-top: 10%
        box-sizing: border-box

    .main_area
        text-align : center
        h4
            margin-left: 13%
            margin-top: 5%
            margin-bottom: 5%
            color: black
        .inicio_button
            margin-right: auto
            margin-left: auto
            margin-top: 10%
            width: 100%
            border: 1px solid #F83200
            border-radius: 25px
            color: #fff
            background-color: #F83200
            font-weight: bold
            font-size: 150%
            &:hover
                background-color: #FFF
                color: #F83200
                border: 1px solid #FFF
    html
        background-color: #d9d9d9
//Para celular en vertical
@media only screen and (max-width: 992px) 
    .body_inicio
        margin-bottom: 50px
        margin-right: auto
        margin-left: auto
    .container
        max-width: 100%
        margin-right: auto
        margin-left: auto
       
        padding-left: 5%
        padding-right: 5%
        box-sizing: border-box
        margin-bottom: 10%
    .main_area
        float: left
        width: 100% 
        padding-right: 0%
        h4
            margin-left: 5%
            margin-top: 5%
            margin-bottom: 5%
            color: black
        .inicio_button
            margin-left: -5%
            margin-top: 10%
            width: 100%
            border: 1px solid #F83200
            border-radius: 25px
            color: #fff
            background-color: #F83200
            font-weight: bold
            font-size: 150%
            &:hover
                background-color: #FFF
                color: #F83200
                border: 1px solid #FFF
        a
            margin-left: 4% 
    .footer_inicio
        width: 100%
        padding-left: 0%
        margin-right: auto
        margin-left: auto
        margin-top: 2%
        box-sizing: border-box
    .terminos
        width: 100%
        padding-left: 0%
        margin-top: 5%
        box-sizing: border-box
        color: black
    html
        background-color: #d9d9d9
    .imagenes_promocion
        padding-left: 10%
    .image_logo_colombia
        margin-left: 25%
        margin-top: 5%
        margin-bottom: 5%
    /* Small devices (portrait tablets and large phones, 600px and up) */
    //@media only screen and (min-width: 600px) 

    /* Medium devices (landscape tablets, 768px and up) */
    //@media only screen and (min-width: 768px)

    /* Large devices (laptops/desktops, 992px and up) */

    /* Extra large devices (large laptops and desktops, 1200px and up) */
    //@media only screen and (min-width: 1200px)
@media only screen and (max-height: 992px)