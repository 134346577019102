//$backgroundImage: url('../images/home/Bg-Dektop-Lista@2x.png'  )

.relleno
    margin-bottom: 20%
    width: 200%
.body_primer_torneo
    margin-top: 2.5%
body
    //background-image: $backgroundImage
    background-size: cover
    background-repeat: no-repeat

.container_tablas
    max-width: 100%
    margin-right: auto
    margin-left: auto
    background-color: #000
    padding-left: 5%
    padding-right: 5%
    box-sizing: border-box

.right_area_torneo
    width: 18% 
    float: right
.celda_tabla
    margin-bottom: 2%
    width: 100px
.row_table
    font-size: 20
@media only screen and (min-width: 992px) 
    .left_area_torneo
        width: 18% 
        float: left
        padding-right: 5%
    .main_area_torneo
        width: 64% 
        float: left
        box-sizing: border-box
        a
            padding-left: 20%
        .buscar_button
            margin-left: 25%
            margin-top: 2%
            margin-bottom: 5%
            width: 50%
            border-radius: 25px
            color: #fff
            background-color: #F83200
            font-weight: bold
            font-size: 150%
            &:hover
                background-color: #fff
                color: #F83200
        .buscar_input_div
            width: 100%
            margin-left: 25%
            margin-top: 2%
            .buscar_input
                box-sizing: border-box
                width: 50%
                height: 50px
                padding-left: 5%
                border-radius: 25px
                border: 1px solid #021d49
                color: #000
                background-color: transparent
    .h4_title
        color: #000
        margin-left: 38%
        margin-top: 2%
        
@media only screen and (max-width: 992px)
    
    .left_area_torneo
        width: 10% 
        float: left
        margin-left: -8%
        margin-right: 0%
        box-sizing: border-box
    .h4_title
        color: #000
        margin-left: 12%
        margin-top: 5%
    @media only screen and (max-height: 415px) 
        .left_area_torneo
            width: 20% 
            float: left
            margin-left: 0%
            margin-right: 10%
            box-sizing: border-box
        .h4_title
            color: 000
            margin-left: 30%
            margin-top: 5%
    .main_area_torneo
        width: 90% 
        float: left
        box-sizing: border-box
        margin-left: 5%
        a
            padding-left: 26%
        .buscar_button
            margin-left: 15%
            margin-top: 5%
            margin-bottom: 5%
            width: 70%
            height: 40px
            border-radius: 25px
            color: #FFF
            background-color: #F83200
            font-weight: bold
            font-size: 100%
            &:hover
                background-color: #FFF
                color: #F83200
                border: 1px solid #FFF

        .buscar_input_div
            width: 100%
            margin-left: 15%
            margin-top: 5%
            .buscar_input
                box-sizing: border-box
                width: 70%
                height: 40px
                padding-left: 5%
                border-radius: 25px
                border: 1px solid #FFFFFF
                background-color: #FFFFFFAA
        .logo_torneo
            margin-left: -15%
            margin-top: 10%
            box-sizing: border-box
    