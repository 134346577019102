@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
}

.primer-torneo,
.segundo-torneo {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
}

.torneo-searchbar{
  display: flex;
  justify-content: center;
}

.tabla-excel{
  justify-content: center;
}
table {
  width: 60%;
  border  : 2px solid;
}

td {
  text-align: center;
}

.div-img{
  float: right;
  width: 300px;
  border: 3px;
  padding: 10px;
}

img {
  opacity: 0.9;
  position: relative;
  min-width: 120px;
  
}

.search-cosito{
  display: flex;
}


.content-table {
  border-collapse: collapse;
  margin: 25px 20px;
  font-size: 0.9em;
  min-width: 220px;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  box-shadow: 0 0 20px #1b3766;
}

.content-table thead tr {
  background-color: #1b3766;
  color: #ffffff;
  text-align: left;
  font-weight: bold;
}

.content-table th,
.content-table td {
  padding: 12px 15px;
  text-align: center;
}

.content-table tbody tr {
  border-bottom: 1px solid #1b3766;
  border-top: 1px solid #00ae42;
}

.content-table tbody tr:nth-of-type(even) {
  background-color: #1b37666c;
}

.content-table tbody tr:last-of-type {
  border-bottom: 2px solid #1b3766;
}

.content-table tbody tr.active-row {
  font-weight: bold;
  color: #1b3766;
}


.content-table-2 {
  border-collapse: collapse;
  margin: 25px -15px;
  font-size: 0.8em;
  min-width: 0px;
  width: 100px;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  box-shadow: 0 0 50px #1b3766;
}

.content-table-2 thead tr {
  background-color: #1b3766;
  color: #ffffff;
  text-align: left;
  font-weight: bold;
}

.content-table-2 th,
.content-table-2 td {
  padding: 12px 15px;
  text-align: center;
}

.content-table-2 tbody tr {
  border-bottom: 1px solid #1b3766;
}

.content-table-2 tbody tr:nth-of-type(even) {
  background-color: #1b37666c;
}

.content-table-2 tbody tr:last-of-type {
  border-bottom: 2px solid #1b3766;
}

.content-table-2 tbody tr.active-row {
  font-weight: bold;
  color: #1b3766;
}

.button-cerrar{
  padding-left: 20px;
  border-radius: 25px;
}